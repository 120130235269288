// Here you can add other styles

.osrit-bc-scheduled {
  background-color: #007bff;
}

.osrit-bc-delivered {
  background-color: #28a745;
}

.osrit-bc-refused {
  background-color: #dc3545;
}

.osrit-bc-paid {
  background-color: #28a745;
}

.osrit-bc-invoicePending {
  background-color: #ffc107;
}

.osrit-bc-inTransit {
  background-color: #ffc107;
}

.osrit-txt-scheduled {
  color: red;
}
// Date InputDatePicker
.react-datepicker-wrapper {
  width: 100%;
}

.card {
  // border: 0;
  // box-shadow: 0 0 8px 5px #f0f3f5;
}

.react_osrit_date_picker {
  width: 100%;
  border: 1px solid #e3e7ea;
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  &.error {
    border: 1px solid #f86c6b;
  }
}

.react_osrit_date_picker {
  width: 100%;
  border: 1px solid #e3e7ea;
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  &.error {
    border: 1px solid #f86c6b;
  }
}

.osrit_dispatch_data {
  &.row {
    margin-top: 0;
    margin-bottom: 2px;
  }

  &.small {
    margin-bottom: 0;

    dd,
    dt {
      font-size: 10px;
    }
  }

  dt {
    margin-bottom: 0.1rem;
    padding-right: 0;
  }

  dd {
    margin-bottom: 0.1rem;
    font-size: 12px;
    padding-right: 0;
  }
}

.osrit_more_button {
  font-size: 10px;
  color: #2196f3;
  cursor: pointer;
  margin-left: 5px;
}

.form-inline {
  display: inline;
}

.form-group {
  display: inline;
}
@media (min-width: 576px) {
  .form-inline .form-group {
    display: inline;
  }
}

.osrit_checkbox {
  label {
    font-size: 15px;
    cursor: pointer;
  }
  /*** custom checkboxes ***/
  input[type="checkbox"] {
    display: none;
  }
  /* to hide the checkbox itself */
  input[type="checkbox"]+label:before {
    font-family: FontAwesome;
    display: inline-block;
  }

  input[type="checkbox"]+label:before {
    content: "\f096";
  }

  input[type="checkbox"]:disabled+label:before {
    content: "\f0c8";
    color: #ccc;
  }
  /* unchecked icon */
  input[type="checkbox"]+label:before {
    letter-spacing: 10px;
  }
  /* space between checkbox and label */
  input[type="checkbox"]:checked+label:before {
    content: "\f046";
  }
  /* checked icon */
  input[type="checkbox"]:checked+label:before {
    letter-spacing: 5px;
    color: #21a8d8;
  }
}

.rc-steps.rc-steps-horizontal.rc-steps-label-vertical {
  .rc-steps-item-container {
    .rc-steps-item-tail {
      margin-left: 65px !important;
      width: 95%;
    }

    .rc-steps-item-icon {
      min-height: 40px;
    }

    .rc-steps-item-content {
      margin-left: 15px;
    }
  }

  .item_not_selected {
    .rc-steps-item-title {
      color: #ccc;
    }
  }

  .item_selected {
    .rc-steps-item-title {
      color: black;
      font-weight: 800;
      font-size: 20px;
    }
  }
}

.osrit_bol_table {
  table {
    border-collapse: collapse;
    width: "100%";
  }

  table,
  td,
  th {
    border: 1px solid black;
  }

  th {
    background-color: #ccc;
  }

  td,
  th {
    padding: 4px;
  }
}

.card {
  &.top_nav {
    border-top: 0;
  }
}

.docBtn {
  background-color: #0085e4;
  color: white;
  padding: 3px 12px;
  border: none;
  border-radius: 5px;
};

.rw-datetime-picker.rw-widget {
  .rw-widget-container {
    font-size: 11px;
    border: #e5e7ea 1px solid;
    min-height: 35px;

    input {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 12px;
    }

    .rw-select.rw-select-bordered {
      button {
        margin-top: -3px;
      }
    }
  }
}

.modal-body {
  padding: 30px;
}

.alert{
  margin-top: 20px;
  text-align: center;
}
